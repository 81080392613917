const availabilityType = {
  "TYPES" : [
    {
      Value : 'STOPSALE',
      Title : 'Müsait Değil',
      Shorthand : 'MD',
    },
    {
      Value : 'ONREQUEST',
      Title : 'Sor Sat',
      Shorthand: 'SS',
    },
    {
      Value : 'AVAILABLE',
      Title : 'Müsait',
      Shorthand: 'M',
    },],
};
export default availabilityType;
