
import {computed, defineComponent, ref} from "vue";
import {ErrorMessage, Field, Form} from "vee-validate";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css';
import {Turkish} from "flatpickr/dist/l10n/tr"
import Tagify from '@yaireo/tagify';
import roomProfitType from "@/core/data/roomprofittype";
import {dateConvert} from "@/core/filters/datatime";
import availabilityType from "@/core/data/availabilitytypes";

interface newItemData {
  itemStart: string;
  itemFinish: string;
  itemMarkets: string;
  itemRoom: string;
  itemStatus: string;
}

export default defineComponent({
  name: "new-over-commission-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: {
    hotelID: String,
  },
  setup(props) {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const newRoomModalRef = ref<null | HTMLElement>(null);


    const myDetails2 = computed(() => {
      return store.getters.hotelDetails;
    });

    const myRoomsList = computed(() => {
      return store.getters.hotelRoomsList;
    });

    function setDatePicker(sDate,fDate){
      let myConfigS = {
        "locale": Turkish,
        dateFormat: "d-m-Y (D)",
        defaultDate: sDate,
      };
      let myConfigF = {
        "locale": Turkish,
        dateFormat: "d-m-Y (D)",
        defaultDate: fDate,
      };
      let tmpFinishDate = flatpickr("#kt_datepicker_1", myConfigF);
      let tmpStartDate = flatpickr("#kt_datepicker_2", myConfigS);
    }

    const myDates = computed(() => {
      let tmpArry =store.getters.getHotelAvailableAddDates;
      console.log(tmpArry);
      if(tmpArry['DATES']!==undefined) {
        setData(tmpArry);
        if (tmpArry.StartDate) setDatePicker(dateConvert.method.convertToDate(tmpArry.StartDate), dateConvert.method.convertToDate(tmpArry.FinishDate))
        let tags = (document.getElementById('myTagsMarkets') as HTMLInputElement);
        if(tags.value!='')tagify.removeAllTags();
      }
      return tmpArry;
    });

    const setData = (tmpArry) => {
      newItemData.value.itemStart = dateConvert.method.dateToflatpicker(tmpArry['DATES'][0]['DATE']);
      newItemData.value.itemFinish = dateConvert.method.dateToflatpicker(tmpArry['DATES'][1]['DATE']);
      newItemData.value.itemRoom = tmpArry['FILTER']['Room'];
    }

    const newItemData = ref<newItemData>({
      itemStart: "",
      itemFinish: "",
      itemMarkets: "",
      itemRoom: "",
      itemStatus: "",
    });

    const validationSchema = Yup.object().shape({
      itemStart: Yup.string().required().label("Başlangıç Tarihi"),
      itemFinish: Yup.string().required().label("Bitiş Tarihi"),
      itemRoom: Yup.string().required().label("Oda Türü"),
      itemStatus: Yup.string().required().label("Müsaitlik Türü"),
    });
    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }
      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");


      const payload1 = {
        HotelID: myDetails2.value.ID,
        StartDate: dateConvert.method.flatpickerToDateYM(newItemData.value.itemStart),
        FinishDate: dateConvert.method.flatpickerToDateYM(newItemData.value.itemFinish),
        Market: (typeof newItemData.value.itemMarkets !="string") ? (JSON.stringify(newItemData.value.itemMarkets)).replace(/\\/g, '') : '""',
        Room: newItemData.value.itemRoom,
        Status: newItemData.value.itemStatus,
        Filter: {
          'StartDate' : myDates.value['FILTER']['StartDate'],
          'FinishDate' : myDates.value['FILTER']['FinishDate'],
          'Room' : myDates.value['FILTER']['Room'],
          'Market' : myDates.value['FILTER']['Market'],
          'HotelID' : myDates.value['FILTER']['HotelID'],
        }
      }

      store.dispatch(Actions.HOTEL_AVAILABLE_INSERT, payload1)
          .then(() => {
            Swal.fire({
              text: "Tebrikler. Başarılı bir şekilde ekleme işlemini tamamladınız.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Geri Dön!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              submitButtonRef.value.disabled = false;
              submitButtonRef.value.setAttribute("data-kt-indicator", "off");
              hideModal(newRoomModalRef.value);
              // Go to page after successfully login
              //router.push({name: "userlist"});
            });
          }).catch(() => {
        Swal.fire({
          text: store.getters.getHotelAvailableErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Tekrar dene!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
        submitButtonRef.value.disabled = false;
        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
      });

    };


    /***********MARKETS START************/
    /***********MARKETS START************/
    /***********MARKETS START************/
    store.dispatch(Actions.MARKETS_LIST_CACHE, {});
    const myMarkets = computed(() => {
      return store.getters.MarketsListCache;
    });
    const myTagsMarkets = computed(() => {
      if (myMarkets.value.data) {
        let tmpList = [];
        for (let i = 0; i < myMarkets.value.data.length; i++) {
          tmpList[i] = {
            value: (myMarkets.value.data[i].Title).trim() + "(" + myMarkets.value.data[i].Cur + ")",
            title: (myMarkets.value.data[i].ShortTitle).trim(),
            cur: myMarkets.value.data[i].Cur,
            type: 'market',
            ID: myMarkets.value.data[i].ID,
          };

        }
        setTagInput(tmpList)
        return tmpList;

      } else {
        return [];
      }
    });
    let tagify;
    const setTagInput = (wList) => {
      var input = document.querySelector('input[name=myTagsInput]');
// init Tagify script on the above inputs
      tagify = new Tagify(input, {
        tagTextProp: 'value', // very important since a custom template is used with this property as text
        enforceWhitelist: true,
        duplicates: false,
        skipInvalid: true, // do not remporarily add invalid tags
        whitelist: wList,
        dropdown: {
          searchKeys: ["value"],
          maxItems: 5,
          closeOnSelect: false,
          position: "text",
          enabled: 0, // always opens dropdown when input gets focus
        }
      });


    }
    function onMarketChange(e) {
      //console.log(e.target.value)
      if(e.target.value !== "") {
        let tmpObj = JSON.parse(e.target.value);
        newItemData.value.itemMarkets = tmpObj;
      }else{
        let tmpObj = "";
        newItemData.value.itemMarkets = tmpObj;
      }
    }
    /***********MARKETS END************/
    /***********MARKETS END************/
    /***********MARKETS END************/




    return {
      myDetails2,
      myRoomsList,
      newItemData,
      myTagsMarkets,
      validationSchema,
      submit,
      onMarketChange,
      submitButtonRef,
      newRoomModalRef,
      roomProfitType,
      dateConvert,
      availabilityType,
      myDates,
    };
  },
});
